import React from 'react'
import PropTypes from 'prop-types'

const Button = ({
  addVariantToCart,
  product,
  text,
  large,
  isDark = true,
  discount,
  discountPrice,
  variant
}) => (
  <button
    className={`
      btn
      ${isDark ? 'cta' : ''}
      ${large ? 'lg' : ''}
      ${variant || 'primary'}
    `}
    onClick={() => addVariantToCart(product.id)}
    type="button"
  >
    <span>
      {text}
      {
        discount ? (
          <span className={`discount ${isDark ? 'dark' : 'light'}`}>
            $
            {discountPrice}
          </span>
        ) : null
      }
      <span className="price">
        &#36;
        {Math.round(product.price.amount)}
      </span>
    </span>
  </button>
)

Button.propTypes = {
  addVariantToCart: PropTypes.func,
  product: PropTypes.shape({
    id: PropTypes.string,
    price: PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  }),
  text: PropTypes.string,
  large: PropTypes.any,
  isDark: PropTypes.bool,
  discount: PropTypes.any,
  discountPrice: PropTypes.any,
  variant: PropTypes.string,
}

export default Button
