import { isBlackFriday } from './discountAction'

const fullCollectionPrice = {
  unit: '$',
  fullPrice: isBlackFriday ? '99' : '139',
  discountedPrice: isBlackFriday ? '49' : '99',
  discountPercentage: isBlackFriday ? '50%' : '40%'
}

export default fullCollectionPrice
