import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { window } from 'browser-monads'
import NProgress from 'nprogress'

// Components
import Nav from '../components/Navs/Nav'
import Sticky from '../components/Navs/Sticky'
import Footer from '../components/Footer'
import ErrorNotification from '../components/ErrorNotification'
import CookieNotification from '../components/Cookies'
import BlackFridayTopBanner from '../components/BlackFriday/BlackFridayTopBanner'
import Modal from '../components/Modal'
import client from '../shopify'
import { LayoutContextProvider } from '../lib/context'

// Styles
import '../scss/master.scss'

class Layout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checkout: { lineItems: [] },
      products: [],
      shop: {},
      query: '',
      searchIsShown: false,
      queryIsTooShort: false,
      isModalShown: false,
      subPage: '',
      fullCollectionId: '',
      errorMessage: ''
    }

    this.addVariantToCart = this.addVariantToCart.bind(this)
    this.initShopifyData = this.initShopifyData.bind(this)
    this.toggleModalShow = this.toggleModalShow.bind(this)
    this.closeSearch = this.closeSearch.bind(this)
    this.showErrorNotification = this.showErrorNotification.bind(this)
  }

  componentDidMount() {
    const { location: { pathname } } = this.props
    const noSlashes = pathname.replace(/\//g, '')

    this.setState({ subPage: noSlashes })
    this.initShopifyData()
  }

  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props
    if (prevProps.location.pathname !== pathname) {
      const noSlashes = pathname.replace(/\//g, '')

      this.setState({
        subPage: noSlashes,
        searchIsShown: false
      })
    }
  }

  closeModal = () => {
    this.setState({
      isModalShown: false
    })
  }

  closeSearch = () => {
    this.setState({
      searchIsShown: false,
      queryIsTooShort: false,
      query: ''
    })
  }

  querySearch = (e) => {
    this.setState({
      query: e.target.value
    }, this.toggleSearch)
  }

  toggleSearch = () => {
    this.setState(({ query }) => ({
      searchIsShown: (query.length > 2),
      queryIsTooShort: (
        query.length >= 1
          && query.length < 3
      )
    }))
  }

  addVariantToCart(setId) {
    // Start loading indicator
    NProgress.start()

    const lineItemsToAdd = [{
      variantId: setId,
      quantity: 1
    }]

    // Create a new checkout.
    client.checkout.create()
      .then(async(checkout) => {
        try {
          const { id } = checkout
          NProgress.inc()

          // Add to cart
          const res = await client.checkout.addLineItems(id, lineItemsToAdd)
          NProgress.done()

          // Open the checkout
          window.location.assign(res.webUrl, 'Jollycons checkout')
        } catch (err) {
          console.error('addVariantToCart ran in to a error:\n', err)
          this.showErrorNotification(err)

          NProgress.done()
        }
      })
  }

  toggleModalShow() {
    this.setState(({ isModalShown }) => ({ isModalShown: !isModalShown }))
  }

  initShopifyData() {
    client
      .collection
      // @ts-ignore
      .fetchAllWithProducts({ first: 250, productsFirst: 250 })
      .then((collections) => {
        const sets = collections.find(collection => (collection.handle === 'icon-sets'))
        const fullCollection = collections.find(collection => (collection.handle === 'full-collection'))
        const fullCollectionId = fullCollection.products[0].variants[0].id

        this.setState({
          products: sets,
          fullCollectionId
        })
      })

    client.shop.fetchInfo().then((shop) => {
      this.setState({ shop })
    })
  }

  showErrorNotification(err) {
    this.setState({
      errorMessage: err
    })
  }

  componentDidCatch(err) {
    this.setState({
      errorMessage: err
    })
  }

  render() {
    const { children, location } = this.props
    const {
      products,
      checkout,
      isModalShown,
      searchIsShown,
      queryIsTooShort,
      query,
      fullCollectionId,
      errorMessage,
      subPage
    } = this.state

    const {
      addVariantToCart,
      toggleModalShow,
      showErrorNotification
    } = this

    const passToChildren = {
      ...this.props,
      ...this.state,
      addVariantToCart,
      lineItems: checkout.lineItems,
      toggleModalShow,
      showErrorNotification
    }

    return (
      <LayoutContextProvider value={passToChildren}>
        <div>
          <Helmet>
            <title>Jollycons</title>

            <meta property="og:image:width" content="1198" />
            <meta property="og:image:height" content="627" />
            <meta property="og:description" content="Gorgeous icons with a fun &amp; friendly touch." />
            <meta property="og:title" content="Jollycons" />
            <meta property="og:image" content="https://jollycons.com/og-image.png" />
            <meta property="og:url" content="https://jollycons.com" />

            <meta name="twitter:domain" content="https://jollycons.com/" />
            <meta name="twitter:card" content="photo" />
            <meta name="twitter:site" content="@yummygum" />
            <meta name="twitter:creator" content="@yummygum" />
            <meta name="twitter:image" content="https://jollycons.com/og-image.png" />
            <meta name="twitter:title" content="Jollycons Website" />
            <meta name="twitter:description" content="Gorgeous icons with a fun &amp; friendly touch." />
          </Helmet>

          <BlackFridayTopBanner />

          <Sticky>
            <Nav
              querySearch={this.querySearch}
              isShown={searchIsShown}
              lineItems={checkout.lineItems}
              pathname={location.pathname}
              closeSearch={this.closeSearch}
              {...{
                queryIsTooShort,
                query,
                addVariantToCart,
                fullCollectionId,
                products
              }}
            />
          </Sticky>

          {children}

          <Footer subPage={subPage} />

          <CookieNotification />
          {
            errorMessage && (
              <ErrorNotification errorMessage={errorMessage} />
            )
          }

          <Modal
            isShown={isModalShown}
            close={this.closeModal}
            fullCollectionId={fullCollectionId}
            addVariantToCart={addVariantToCart}
            lineItems={checkout.lineItems}
          />
        </div>
      </LayoutContextProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
}

export default Layout
