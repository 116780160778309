// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-redirect-to-categories-js": () => import("../src/templates/redirect-to-categories.js" /* webpackChunkName: "component---src-templates-redirect-to-categories-js" */),
  "component---src-templates-single-js": () => import("../src/templates/single.js" /* webpackChunkName: "component---src-templates-single-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-index-js": () => import("../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-faq-index-js": () => import("../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-license-index-js": () => import("../src/pages/license/index.js" /* webpackChunkName: "component---src-pages-license-index-js" */),
  "component---src-pages-privacy-index-js": () => import("../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-releases-index-js": () => import("../src/pages/releases/index.js" /* webpackChunkName: "component---src-pages-releases-index-js" */),
  "component---src-pages-sets-index-js": () => import("../src/pages/sets/index.js" /* webpackChunkName: "component---src-pages-sets-index-js" */),
  "component---src-pages-thanks-index-js": () => import("../src/pages/thanks/index.js" /* webpackChunkName: "component---src-pages-thanks-index-js" */)
}

