import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { window } from 'browser-monads'
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { Location } from '@reach/router'

const CustomLink = ({
  location: {
    pathname
  },
  to,
  children,
  className = ''
}) => (
  pathname === to ? (
    <button
      className={`scroll-link ${className}`}
      onClick={() => window.scrollTo(0, 0)}
      type="button"
    >
      {children}
    </button>
  ) : (
    <Link to={`/${to.substring(1)}`} className={className}>{children}</Link>
  )
)

CustomLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

const WrappedCustomLink = props => (
  <Location>
    {
      ({ location }) => (
        <CustomLink {...props} location={location} />
      )
    }
  </Location>
)

export default WrappedCustomLink
