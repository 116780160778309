import React from 'react'

const defaultContextValue = {
  checkout: { lineItems: [] },
  products: [],
  shop: {},
  query: '',
  searchIsShown: false,
  queryIsTooShort: false,
  isModalShown: false,
  subPage: '',
  fullCollectionId: '',
}

const { Provider, Consumer } = React.createContext(defaultContextValue)

export {
  Provider as LayoutContextProvider,
  Consumer as LayoutContextConsumer
}
