import { navigate } from 'gatsby'

/* Validate that the array contains at least one item */
export const validateArray = (arr) => {
  if (((arr && arr.length < 1) || !arr) || !Array.isArray(arr)) {
    return false
  }

  return true
}

/* Filter array on slug */
export const getProductBySlug = (slug, products) => {
  const filtered = validateArray(products) ? (
    products.filter(product => (
      product.handle === slug
    ))
  ) : [{}]

  return filtered[0]
}

/* Filter function that filters the product array. */
export const getProductID = (slug, products) => {
  if (!validateArray(products)) { return }
  const selectedProduct = getProductBySlug(slug, products)

  if (selectedProduct
    && selectedProduct.variants
    && selectedProduct.variants[0]) {
    return selectedProduct.variants[0].id
  } else if (selectedProduct && selectedProduct[0]) {
    return selectedProduct[0].variants[0].id
  }

  const noVariantsError = new Error(`selectedProduct expected a Object with a Array of variants got ${selectedProduct} instead.`)
  throw noVariantsError
}

/* Title to dash */
export const titleToDash = title => title.replace(/\s+/g, '-').toLowerCase()

/* Check if the target isn't a button, and then redirect to the page. */
export const handleProductClick = (e, slug, selector) => {
  if ((selector && e.target.closest(selector)) || e.target.closest('a')) {
    return null
  }

  navigate(`/categories/${slug}`)
}

/* Truncate set name */
export const truncateSetName = (name, max) => {
  if (name.length >= max) {
    return `${name
      .substring(0, max)
      .trim()
      .replace(/([&])$/, '')
      .trim()}..`
  }

  return name
}
