import React from 'react'
import PropTypes from 'prop-types'
import { window } from 'browser-monads'

// Components
import Logo from '../Logo'
import SearchBlock from './SearchBlock'

const isMobile = (window.innerWidth < 562)

class Nav extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dealVisible: false
    }

    this.searchInputRef = React.createRef()
  }

  componentDidMount() {
    const setsOverview = document.querySelector('#sets-overview')

    if (setsOverview && isMobile) {
      window.addEventListener('scroll', () => this.checkDistance(setsOverview))
    }
  }

  clearInputQuery = () => {
    this.searchInputRef.current.value = ''
  }

  checkDistance = (elem) => {
    const elementToTop = elem.offsetTop + 100
    const scrollDistance = window.scrollY

    if (scrollDistance > elementToTop) {
      this.setState({ dealVisible: true })
    } else {
      this.setState({ dealVisible: false })
    }
  }

  render() {
    const {
      isSticky,
      isScrolledBy,
      querySearch,
      queryIsTooShort,
      isShown,
      query,
      addVariantToCart,
      fullCollectionId,
      lineItems,
      products,
      closeSearch,
      pathname
    } = this.props

    const { dealVisible } = this.state

    return (
      <nav className={`main-nav ${isSticky ? 'is-sticky' : ''} ${isShown ? 'is-shown' : ''}`}>
        <div className="container-md nav-container">
          <div className="content">
            <div className="logo-left">
              <Logo height={isMobile ? 28 : 36} />
            </div>
            <div
              className={`search-wrapper ${isSticky
                || pathname.includes('/categories') ? 'visible' : ''}`}
            >
              <input
                ref={this.searchInputRef}
                type="text"
                placeholder="Search for icons"
                className={`search ${isSticky ? 'search__sticky' : ''}`}
                onChange={querySearch}
                required
              />

              <span className={`search-icon__wrapper ${isSticky ? 'search-icon__sticky' : ''}`}>
                <svg height="22" viewBox="0 0 22 22" width="22" xmlns="http://www.w3.org/2000/svg">
                  <path
                    className="search-icon__path"
                    d="m9.06327102 11.2520405c-.8860703.5051603-1.91279532.7939591-3.0073417.7939591-3.34459741 0-6.05592932-2.69658887-6.05592932-6.02299981 0-3.32641093 2.71133191-6.02299979 6.05592932-6.02299979 3.34459742 0 6.05592928 2.69658886 6.05592928 6.02299979 0 1.16013012-.3297951 2.24365129-.9012295 3.16288689l2.3380751 2.26517062c.5907431.5723228.603139 1.51257.0276871 2.1001009-.5754519.5875308-1.5208398.5998593-2.1115828.0275365zm-3.0073417-2.17628739c1.69520691 0 3.06944364-1.36676422 3.06944364-3.05275332s-1.37423673-3.05275332-3.06944364-3.05275332c-1.6952069 0-3.06944363 1.36676422-3.06944363 3.05275332s1.37423673 3.05275332 3.06944363 3.05275332z"
                    fill="#9AACC3"
                    transform="translate(4 4)"
                  />
                </svg>
              </span>
            </div>
            <div className="menu right">

              <ul>
                <li
                  className={`nav-button ${isScrolledBy ? 'visible' : ''} ${dealVisible ? 'full' : ''}`}
                >
                  <button
                    className="btn primary cta md filled"
                    onClick={() => addVariantToCart(fullCollectionId)}
                    type="button"
                  >
                    Buy Jollycons
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <SearchBlock
          clearInputQuery={this.clearInputQuery}
          {...{
            isShown,
            queryIsTooShort,
            query,
            addVariantToCart,
            products,
            lineItems,
            closeSearch
          }}
        />
      </nav>
    )
  }
}

Nav.propTypes = {
  isSticky: PropTypes.bool,
  isShown: PropTypes.bool,
  query: PropTypes.string,
  isScrolledBy: PropTypes.bool,
  pathname: PropTypes.string,
  lineItems: PropTypes.array,
  querySearch: PropTypes.func,
  closeSearch: PropTypes.func,
  products: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  queryIsTooShort: PropTypes.bool,
  addVariantToCart: PropTypes.func,
  fullCollectionId: PropTypes.string
}
export default Nav
