import React from 'react'
import PropTypes from 'prop-types'

import Link from '../Link'

const ProductSingle = ({ name, set }) => (
  <div className="product-single">
    <div className="product-single-bg" />
    <img
      src={require(`../../images/categories/${set}/icon-${set}-${name}@2x.png`)}
      alt={`${name} icon from the ${set} set.`}
    />
    <Link className="text-link violet small" to={`/categories/${set}`}>View Category</Link>
  </div>
)

ProductSingle.propTypes = {
  name: PropTypes.string,
  set: PropTypes.string
}

export default ProductSingle
