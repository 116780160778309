import React, { Component } from 'react'
import PropTypes from 'prop-types'

import icons from '../../icons.json'

import ProductSingle from '../Shopify/ProductSingle'
import ProductSearch from '../Shopify/ProductSearch'

import { getProductBySlug } from '../../utils/helpers'

class SearchBlock extends Component {
  constructor(props) {
    super(props)

    this.state = {
      filteredIcons: [],
      filteredSets: []
    }

    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    const availableSets = icons
      .map(icon => icon.set)
      .filter((set, idx, arr) => arr.indexOf(set) === idx)

    const filteredSets = availableSets.reduce((acc, set) => {
      acc.push(this.getSet(set))
      return acc
    }, [])

    this.setState({
      filteredSets
    })
  }

  componentWillReceiveProps(nextProps) {
    const filteredArray = arr => arr.filter((item, idx, arr) => arr.indexOf(item) === idx)

    const { filteredIcons } = this.state
    const setStringsFromIcons = filteredArray(filteredIcons.map(({ set }) => set))

    const { query, queryIsTooShort } = this.props
    if (nextProps.query !== query
      || nextProps.queryIsTooShort !== queryIsTooShort) {
      this.setState({
        filteredIcons: this.filterIcons(nextProps.query),
      })
    }

    this.setState({
      filteredSets: setStringsFromIcons
    })
  }

  getSet = set => icons.reduce((acc, icon) => {
    if (icon.set === set) {
      acc.push(icon)
    }
    return acc
  }, [])

  filterTags = (tags, query) => (
    tags.filter(tag => (
      tag.toLowerCase().includes(query.toLowerCase())
        || (tag.slice(-1) === query.trim().slice(-1)
          && tag.toLowerCase().includes(query.toLowerCase().trim())
        ) ? tag : null
    ))
  )

  filterIcons = query => (
    icons.filter((icon) => {
      const filteredTags = this.filterTags(icon.tags, query)
      return filteredTags.length > 0 ? icon : null
    })
  )

  handleClose = () => {
    const { closeSearch, clearInputQuery } = this.props
    closeSearch()
    clearInputQuery()
  }

  render() {
    const {
      isShown,
      queryIsTooShort,
      query,
      addVariantToCart,
      lineItems,
      products
    } = this.props
    const { filteredIcons, filteredSets } = this.state

    return (
      <div className={`search-block ${isShown || queryIsTooShort ? 'show' : 'hidden'}`}>
        {
          queryIsTooShort && (
            <div className="search-container fadeInSearch">
              <div className="container-md">
                <div className="grid yg center text-center">
                  <h2>
                    {'Continue '}
                    <span className="light">with search...</span>
                  </h2>
                </div>
              </div>
            </div>
          )
        }

        {
          isShown && (
            <div className="search-container fadeInSearch">
              {
                filteredIcons.length > 0 ? (
                  <div className="container-md">
                    <div className="grid yg center text-center">
                      <div className="col-12 ">
                        <h2>
                          <span className="count">
                            {filteredIcons.length}
                            {' '}
                          </span>
                          <span className="light">results for</span>
                          {' '}
                          {query}
                        </h2>
                      </div>
                    </div>

                    <div className="padding-s-top padding-l-bottom">
                      <div className="eyebrow">Icons matching your search term</div>
                      <div className="grid yg center text-center single-icons">
                        <div className="products-single results">
                          {
                            filteredIcons.map((icon, idx) => (
                              <ProductSingle
                                name={icon.name}
                                set={icon.set}
                                key={idx}
                              />
                            ))
                          }
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="eyebrow">Icon categories matching your search term</div>
                      <div className="grid yg center text-center icon-sets">
                        {
                          filteredSets.map((set, idx) => (
                            <ProductSearch
                              key={`set-${idx}`}
                              product={getProductBySlug(set, products.products)}
                              {...{
                                addVariantToCart,
                                set,
                                lineItems
                              }}
                            />
                          ))
                        }
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="container-md">
                    <div className="grid yg center text-center no-results">
                      <div className="col-12 ">
                        <img
                          className="no-results"
                          src={require('../../images/no-results.svg')}
                          alt="no results"
                        />
                        <h2>
                          <span className="light">No results for</span>
                          {' '}
                          {query}
                        </h2>
                        <a className="text-link large violet" href="/categories">View all icons grouped by category</a>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }

        <div className="close" onClick={() => this.handleClose()} role="button" tabIndex="0">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path fill="#9AACC3" d="M12,13.4142136 L4.70710678,20.7071068 C4.31658249,21.0976311 3.68341751,21.0976311 3.29289322,20.7071068 C2.90236893,20.3165825 2.90236893,19.6834175 3.29289322,19.2928932 L10.5857864,12 L3.29289322,4.70710678 C2.90236893,4.31658249 2.90236893,3.68341751 3.29289322,3.29289322 C3.68341751,2.90236893 4.31658249,2.90236893 4.70710678,3.29289322 L12,10.5857864 L19.2928932,3.29289322 C19.6834175,2.90236893 20.3165825,2.90236893 20.7071068,3.29289322 C21.0976311,3.68341751 21.0976311,4.31658249 20.7071068,4.70710678 L13.4142136,12 L20.7071068,19.2928932 C21.0976311,19.6834175 21.0976311,20.3165825 20.7071068,20.7071068 C20.3165825,21.0976311 19.6834175,21.0976311 19.2928932,20.7071068 L12,13.4142136 Z" transform="translate(-3 -3)" />
          </svg>
        </div>
      </div>
    )
  }
}

SearchBlock.propTypes = {
  isShown: PropTypes.bool,
  queryIsTooShort: PropTypes.bool,
  query: PropTypes.string,
  addVariantToCart: PropTypes.func,
  lineItems: PropTypes.array,
  products: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  closeSearch: PropTypes.func,
  clearInputQuery: PropTypes.func
}

export default SearchBlock
