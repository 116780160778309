import { start } from 'nprogress'
import React from 'react'
import DiscountTag from './DiscountTag'

// Globals
import { isBlackFriday } from '../../lang/discountAction'

function BlackFridayTopBanner() {
  if (isBlackFriday) {
    return (
      <div className="black-friday-top-banner" style={{ backgroundImage: `url('${require('../../images/black-friday/bg-pattern.svg')}')` }}>
        <div className="container-md">
          <h4 className="discount-text">
            50% OFF
            <span> BlackFriday</span>
          </h4>
          <DiscountTag />
        </div>
      </div>
    )
  }

  return null
}

export default BlackFridayTopBanner
