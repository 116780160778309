import React from 'react'
import PropTypes from 'prop-types'

import Link from '../Link'
import Button from './Button'

const ProductSearch = ({
  set,
  addVariantToCart,
  lineItems = [],
  product
}) => (
  <div className="product-search">
    <div className="image-crop">
      <img
        src={require(`../../images/categories/${set}/hero-${set}@2x.png`)}
        alt={`${set} icon set.`}
      />
    </div>

    <div className="action-bar">
      <Link className="text-link violet" to={`/categories/${set}`}>View</Link>
      <Button
        {...{
          text: 'Buy Collection',
          notInBasket: lineItems.some(item => item.title === product.title),
          addVariantToCart,
          product: (product && product.variants) ? product.variants[0] : { price: '', id: '' }
        }}
      />
    </div>

    <div className="product-search-bg" />
  </div>
)

ProductSearch.propTypes = {
  set: PropTypes.string,
  addVariantToCart: PropTypes.func,
  lineItems: PropTypes.array,
  product: PropTypes.object
}

export default ProductSearch
