import React from 'react'
import PropTypes from 'prop-types'
import Link from './Link'
import Logo from './Logo'

const Footer = ({ subPage }) => {
  const year = new Date().getFullYear() || 2000

  return (
    <footer className="footer">
      <div className="main margin-xl-top">
        <div className="container-md">
          <div className="grid no-margin between align-bottom upper">
            <div className="col-6 no-pad">
              <Logo height={45} />
            </div>

            <div className="col-6 no-pad">
              <ul className="text">
                {
                  [
                    'Categories',
                    'FAQ',
                    'License'
                  ].map((item, idx) => (
                    <li key={idx}>
                      <Link to={`/${item.toLowerCase()}`}>{item}</Link>
                    </li>
                  ))
                }

                <li>
                  <a href="mailto:hello@jollycons.com">Contact</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="divider" />

          <div className="container grid align-middle">
            <div className="col-6 left no-pad no-margin">
              <a
                href="https://yummygum.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="made-by margin-xxs-top">
                  Created with
                  <svg
                    className="heart"
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="11"
                    viewBox="0 0 12 11"
                  >
                    <path
                      className="normal"
                      fill="#BABBC4"
                      d="M9.88019289,4.62910297 C10.4282275,4.02778121 10.4561472,3.11673625 9.9459625,2.48298447 L9.78052978,2.27748383 C9.75700283,2.24825865 9.73230182,2.21999838 9.70648747,2.19277251 C9.17850141,1.6359164 8.29906307,1.61251261 7.74220696,2.14049867 L6.51603413,3.30310092 C6.22670129,3.57743338 5.77329871,3.57743338 5.48396587,3.30310092 L4.25779304,2.14049867 C4.23056717,2.11468432 4.2023069,2.08998331 4.17308172,2.06645636 C3.57533318,1.58525507 2.70067152,1.67973529 2.21947022,2.27748383 L2.0540375,2.48298447 C1.54385277,3.11673625 1.57177251,4.02778121 2.11980711,4.62910297 L6,8.88657942 L9.88019289,4.62910297 Z M6.51603413,2.21459961 L5.48396587,2.21459961 L6,1.72531912 L6.51603413,2.21459961 Z M6.71013869,1.05199737 C7.86815747,-0.0459842618 9.69700715,0.0026854664 10.7949888,1.16070425 C10.8486714,1.21732222 10.9000388,1.2760913 10.9489646,1.33686699 L11.1143973,1.54236762 C12.0883863,2.75225736 12.035085,4.49152495 10.9888372,5.63950283 L6.55432214,10.5051999 C6.25684655,10.8316 5.74315345,10.8316 5.44567786,10.5051999 L1.01116284,5.63950283 C-0.0350850059,4.49152495 -0.0883863333,2.75225736 0.885602667,1.54236762 L1.05103539,1.33686699 C2.05172502,0.0938097045 3.87064128,-0.102668097 5.11369857,0.898021534 C5.17447425,0.946947355 5.23324333,0.998314733 5.28986131,1.05199737 L6,1.72531912 L6.71013869,1.05199737 Z"
                    />
                    <path
                      className="hover"
                      fill="#AB56FF"
                      d="M6.71013869,1.05199737 C7.86815747,-0.0459842618 9.69700715,0.0026854664 10.7949888,1.16070425 C10.8486714,1.21732222 10.9000388,1.2760913 10.9489646,1.33686699 L11.1143973,1.54236762 C12.0883863,2.75225736 12.035085,4.49152495 10.9888372,5.63950283 L6.55432214,10.5051999 C6.25684655,10.8316 5.74315345,10.8316 5.44567786,10.5051999 L1.01116284,5.63950283 C-0.0350850059,4.49152495 -0.0883863333,2.75225736 0.885602667,1.54236762 L1.05103539,1.33686699 C2.05172502,0.0938097045 3.87064128,-0.102668097 5.11369857,0.898021534 C5.17447425,0.946947355 5.23324333,0.998314733 5.28986131,1.05199737 L6,1.72531912 L6.71013869,1.05199737 Z"
                    />
                  </svg>
                  by Yummygum
                </p>
              </a>
            </div>

            <div className="col-6 right no-pad">
              <p className="copyright">
                &#169;
                {` ${year}`}
                {' Yummygum - '}
                <Link to="/privacy">Privacy</Link>
              </p>
            </div>
          </div>

        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  subPage: PropTypes.string
}

export default Footer
