const discountEndDate = '28 Nov 2022 23:59:58'
const currentDay = new Date()
const startBlackFriday = new Date('11/25/2022')
const stopBlackFriday = new Date('11/29/2022')
const isBlackFriday = currentDay >= startBlackFriday && currentDay <= stopBlackFriday

export {
  discountEndDate,
  currentDay,
  startBlackFriday,
  stopBlackFriday,
  isBlackFriday
}
