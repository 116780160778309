import React from 'react'
import PropTypes from 'prop-types'
import { window, document } from 'browser-monads'

class Sticky extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isSticky: false,
      isScrolledBy: false
    }

    this.containerRef = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', () => (
      this.fixNav(this.containerRef.current, this.containerRef.current.offsetTop)
    ))
    window.addEventListener('scroll', () => this.scrolledPassedElement('#home-buy-button'))
  }

  fixNav(nav, topOfNav) {
    const IS_STICKY = (window.scrollY.toFixed(0) >= topOfNav)

    this.setState(({ isSticky }) => {
      if (IS_STICKY !== isSticky) {
        return ({
          isSticky: IS_STICKY
        })
      }
    })
  }

  scrolledPassedElement(elementID) {
    const { isScrolledBy } = this.state

    const element = document.querySelector(elementID)
    const bottomPosition = (element) ? element.offsetTop + element.clientHeight : 0

    if (window.scrollY >= bottomPosition && isScrolledBy !== true) {
      this.setState({
        isScrolledBy: true
      })
    } else if (window.scrollY < bottomPosition && isScrolledBy === true) {
      this.setState({
        isScrolledBy: false
      })
    }
  }

  render() {
    const { children } = this.props
    const { isSticky, isScrolledBy } = this.state
    const addClass = () => (
      isSticky ? 'is-sticky is-sticky__wrapper' : 'is-not-sticky is-not-sticky__wrapper'
    )

    const childWithProps = React.Children.map(
      children,
      child => React.cloneElement(child, {
        isSticky,
        stickyClass: addClass(),
        isScrolledBy,
        ...this.props
      })
    )

    return (
      <div
        ref={this.containerRef}
        className={addClass()}
      >
        {childWithProps}
      </div>
    )
  }
}

Sticky.propTypes = {
  children: PropTypes.node
}

export default Sticky
