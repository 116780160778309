import React from 'react'
import PropTypes from 'prop-types'

const refreshPage = () => {
  window.location.reload()
}

const ErrorNotification = ({ errorMessage }) => (
  <div className="error-notification" role="alert">
    <span className="error-indicator" aria-hidden />
    <div className="error-heading">
      <h4>Something went wrong</h4>
      {/* @ts-ignore */}
      <img src={require('../images/icon-error-basket.jpg')} alt="Basket icon" />
    </div>
    <p>{errorMessage.toString()}</p>
    <button
      className="btn primary"
      onClick={refreshPage}
      type="button"
    >
      Refresh Page
    </button>
  </div>
)

ErrorNotification.propTypes = {
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ])
}

export default ErrorNotification
